<template>
<div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card shadow">
          <div class="card-title text-center border-bottom">
            <h4 class="p-3">Merhaba</h4>
          </div>
          <div class="card-body">
            <form>
              <div class="mb-4">
                <label for="username" class="form-label">Kullanıcı Adı</label>
                <input  v-model="username" type="text" class="form-control" id="username" />
              </div>
              <div class="mb-4">
                <label for="password" class="form-label">Şifre</label>
                <input v-model="password" type="password" class="form-control" id="password" />
              </div>
              <div class="mb-4">
                <input  type="checkbox" class="form-check-input" id="remember" />
                <label for="remember" class="form-label">Beni Hatırla</label>
              </div>
              <div class="d-grid">
                <button @click.prevent="robotor" type="submit" class="btn text-light main-bg">Giriş</button>
              </div>
              <div class="d-grid">
                <br/>
                <!--<p > {{this.access_token}} <br/>
                     {{this.client_groups}} <br/>
                     {{this.income_periods}} <br/>
                     {{this.income_types}} <br/>
                     {{this.project_types}} <br/>
                     {{this.project_periods}} <br/>
                     {{this.branches}} <br/>
                     {{this.roles}} <br/>
                     {{this.task_status}} <br/>
                     {{this.unit}} <br/>
                     {{this.urgency}} <br/>
                </p>-->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
  import {mapState} from 'vuex'

export default {

  name: 'LoginComponent',
  computed: mapState([
        'api_url',
        'admin',
        'access_token',
        'refresh_token',
        'client_groups',
        'income_periods',
        'income_types',
        'project_types',
        'project_periods',
        'branches',
        'roles',
        'task_status',
        'unit',
        'urgency'
    ]),
  data () {
    return {
      username: "",
      password: "",
      responseData: null,
      
    }
  },
  
  methods:{
    robotor(){
      const credentials = {username:this.username, password:this.password}
      axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
      axios.post(this.api_url+"login", credentials)
      .then((response) => {
           
            this.$store.dispatch('getToken', response.data.access_token)
            this.$store.dispatch('getRefresh', response.data.refresh_token)
            this.$store.dispatch('getClientGroup')
            this.$store.dispatch('getIncomePeriod')
            this.$store.dispatch('getIncomeType')
            this.$store.dispatch('getProjectType')
            this.$store.dispatch('getProjectPeriod')
            this.$store.dispatch('getBranch')
            this.$store.dispatch('getRole')
            this.$store.dispatch('getTaskStatus')
            this.$store.dispatch('getUnit')
            this.$store.dispatch('getUrgency')
            if(response.status == 200){
              this.$router.push('/')
            }
       })
       
     
    }
  }
}
</script>


<style scoped>

.main-bg {
  background: coral !important;
}

input:focus, button:focus {
  border: 1px solid lightblue!important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: lightblue !important;
  border-color: lightblue !important;
}

.card, .btn, input{
  border-radius:0 !important;
}

</style>